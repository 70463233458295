import React from 'react';

const Hero = () => (
        <div className="hero" >
            <div className="hero-text-wrapper">
            <h2 className="hero-text">WordSnip Helps you compare paragraphs of text to determine resemblance</h2>
            <h2 className="hero-text">Paste or Type the source text in the left text box</h2>
            <h2 className="hero-text">Paste or Type the text you want to compare in the right box</h2>
            <h2 className="hero-text">Click the Compare Text button</h2>
            <h2 className="hero-text">Presto!</h2>
            </div>
        </div>
    )

export default Hero;