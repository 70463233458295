import React from 'react';
import '../../App.css';


const ListItem = (props) => {
    const word = props.word;
    const count = props.count;
    return(
           <div style={{"display":"flex", "justifyContent":"center", "alignItems":"center","flex":"49%", "borderLeft":"1px solid #DDD", "borderRight":"1px solid #DDD"}}>
                <p style={{"width":"80%", "fontSize":"18px","padding":"0px 10px", }}>{word}</p>
                <p style={{"width":"20%", "margin":"0", "textAlign":"center"}}>{count}</p>
            </div>
    )
}

export default ListItem;