import React, { useState }from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import CompareForm from './components/molecules/CompareForm';
import ComparisonTable from './components/ComparisonTable';
import Footer from './components/Footer';
import CompareResultsContext from './stateManagement/results-context';
import './App.css';

const App = () => {

  const [comparisonResults, setComparisonResults] = useState([]);
  return (
    
    <div className="App" >
            <div>
              <Header/>
            </div>
            <div style={{"padding":"10px"}}>
              <Hero/>
            </div>
            <div style={{"background":"#0f2862", "padding":"10px"}}>
              <CompareForm populateResults={setComparisonResults}/>
            </div>
            <div style={{"background":"#0f2862","padding":"10px"}}>
              <CompareResultsContext.Provider value={{resultsData: comparisonResults}}>
                <ComparisonTable/>
              </CompareResultsContext.Provider>
            </div>
            <div>
                <Footer />
            </div>
    </div>
    
  );
}

export default App;
