import React from 'react';
import '../../App.css'; 

const TextArea = (props) => (
        <div className="textarea-wrapper">
            <div className="textarea-label-container">
                <label htmlFor={props.txtareaId}>{props.placeHolder}</label>
            </div>
            <div className="textarea-field-container">
                <textarea id={props.txtareaId} onChange={props.handler} className="textarea" rows="20" cols="50" placeholder={props.placeHolder}></textarea>
            </div>
        </div>
    )

export default TextArea;