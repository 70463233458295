import React from 'react';

const Footer = () => {
    return(
        <div className="footer">
            <div className="copyright">
                <p>&copy; 2021 Copyright WordSnip.com</p>
            </div>
            <div className="privacy-policy">
            </div>
        </div>
    )
}

export default Footer;