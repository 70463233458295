import React, { useState } from 'react';
import axios from 'axios';
import TextArea from '../atoms/TextArea';
import CallToAction from '../atoms/CallToAction'

const CompareForm = (props) => {

    const [leftTextArea, setLeftTextArea] = useState('');
    const [rightTextArea, setRightTextArea] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const user = {}
        user.leftArea = leftTextArea;
        user.rightArea = rightTextArea;
        axios.post('http://www.wordsnip.com/postData', { user })
        .then(res => {
            const data = JSON.parse(res.data.data);
            const leftAreaData =  data.data1;
            const rightAreaData = data.data2;
            const ocurrencesLyst = [];
            for(let k in leftAreaData){
                if(rightAreaData.hasOwnProperty(k)){
                    ocurrencesLyst.push([rightAreaData[k], leftAreaData[k]])
                }
            }
            props.populateResults(ocurrencesLyst)
        })
        .catch(err => console.log(err, 'is the error message'));
    }
    const leftAreaChange = (event) => {
        event.preventDefault();
        setLeftTextArea(event.target.value);
    }
    const rightAreaChange = (event) => {
        event.preventDefault();
        setRightTextArea(event.target.value);
    }
    return(
        <div className="form-wrapper">
            <form onSubmit={handleSubmit}>
            <div className="textarea-wrapper-wrapper">
                <TextArea txtareaId="left-area" handler={leftAreaChange} placeHolder="Source Text" />
                <TextArea txtareaId="right-area" handler={rightAreaChange} placeHolder="Text you want to compare"/>
            </div>
            <div className="submit-wrapper">
                <CallToAction btnType="submit" btnName="Compare Text" />                    
            </div>
            </form>
        </div>                
    )
}

export default CompareForm;