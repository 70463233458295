import React, { useContext } from 'react';
import CompareResultsContext from '../stateManagement/results-context';
import ListItem from './atoms/ListItem';

const ComparisonTable = () => {

    const comparisonResults = useContext(CompareResultsContext);
   
    return (
        <div style={{"height":"auto","display":"flex", "flexDirection":"column", "padding":"5px"}}>
            <div style={{"display":"flex", "padding":"0px 10px", "background":"#333", "color":"#fff", "fontWeigth":"bold", "textAlign":"center", "borderRadius":"15px 15px 0 0"}}>
                <p style={{"flex":"50%"}}>Source Text</p>
                <p style={{"flex":"50%"}}>Text you want to compare</p>
                
            </div>
            <div style={{"display":"flex", "background":"#FFF", "fontWeight":"400"}}>
            <ListItem word={'word'} count={'count'} />
            <ListItem word={'word'} count={'count'} />
            </div>
            {
                comparisonResults.resultsData.map((item, index) => (
                    <div key={item[0][0]+index} style={{"display":"flex", "flexWrap":"wrap", "fontWeight":"400", "background":(index % 2 === 0)?"#DDD":"#FFF"}}>
                        <div style={{"height":"30px", "display":"flex", "flex":"100%"}}>
                        <ListItem word={item[0][0]} count={item[0][1]}  border={"borderRight"}/>
                        <ListItem word={item[1][0]} count={item[1][1]}  border={"borderLeft"}/>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default ComparisonTable;