import React from 'react';
import '../App.css';

const Header = () => {
    return(
        <div className="header">
            <div className="header-logo-wrapper">
                <p className="header-logo-container">
                    WordSnip
                </p>
            </div>
        
        </div>
    )
}

export default Header;